import { useTranslation } from 'react-i18next';
import competitions from '@assets/img/icons/competitions.svg';
import { Link } from 'react-router-dom';

export const Competitions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="section competitions-button">
      <div className="row">
        <div className="col mode-card">
          <Link to="/competitions">
            <div className="competition-mode">
              <div className="mode-icon">
                <img src={competitions} alt="img" />
              </div>
              <div>
                <h2>{t('home.competition-button.title')}</h2>
                <p>{t('home.competition-button.description')}</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
