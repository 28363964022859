import axios from 'axios';
import AuthStorage from '../libs/AuthStorage';
import { TokensData } from '../libs/types';
import { authAPI } from './authAPI';

const getLang = () => {
  const lang = localStorage.getItem('lang');
  return lang || 'en';
};

const instanceAnonymous = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: import.meta.env.VITE_API_URL,
});

instanceAnonymous.interceptors.request.use(
  async (config) => {
    config.headers['Accept-Language'] = getLang();

    return config;
  },
  (error) => Promise.reject(error),
);

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: import.meta.env.VITE_API_URL,
});

instance.interceptors.request.use(
  async (config) => {

    config.headers['Accept-Language'] = getLang();

    const token = AuthStorage.getValidAccessToken();
    if (token) {
      // @ts-ignore
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }

    try {
      const tokens = await refreshTokens();
      // @ts-ignore
      config.headers.Authorization = `Bearer ${tokens.access_token}`;
      return config;
    } catch (error) {
      console.error(error);
      forceLogout();
    }

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error('error', error);
    const originalConfig = error.config;

    if (error.response?.status === 401) {
      if (originalConfig && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const tokens = await refreshTokens();
          originalConfig.headers.Authorization = `Bearer ${tokens.access_token}`;
          return instance(originalConfig);
        } catch (_error: any) {
          forceLogout();
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  },
);

const forceLogout = () => {
  console.error('error and logout');
  AuthStorage.forgetTokens();
  AuthStorage.forgetUser();
  // TODO: redirect via router
  window.location.href = '/login';
};

let refreshing: Promise<TokensData> | null = null;
const refreshTokens = (): Promise<TokensData> => {
  if (refreshing !== null) {
    return refreshing;
  }

  const refreshToken = AuthStorage.getValidRefreshToken();
  if (!refreshToken) {
    throw new Error('No valid refresh token');
  }

  refreshing = authAPI
    .refresh(refreshToken)
    .then((tokens) => {
      AuthStorage.storeTokens(tokens);
      return tokens;
    })
    .finally(() => {
      refreshing = null;
    });

  return refreshing;
};

export { instanceAnonymous, instance };
