import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { gameControllerOutline, notificationsOutline } from 'ionicons/icons';
import { Toast } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import { BottomMenu } from '@components/BottomMenu';
import { PageHeader } from '@components/Header/PageHeader';

import Banner from './components/HeadBanner';
import ModeCardList from './components/ModeCardList';
import { useTelegramClose } from '@hooks/useTelegramClose';

import './home.css';
import { Competitions } from './components/Competitions';

const Home = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  useTelegramClose();

  return (
    <main className="home-page">
      <PageHeader />

      {/* tmp disabled
      rightBtn={
          <div className="headerButton">
            <IonIcon icon={notificationsOutline} />
            <span className="badge badge-primary">0</span>
          </div>
        } */}

      <div id="appCapsule">
        <div className="app-box">
          <Banner />
          <Competitions />
          <ModeCardList fallback={() => setShow(true)} />
        </div>
      </div>

      <Toast
        show={show}
        onClose={() => setShow(false)}
        delay={2000}
        autohide
        id="toast-8"
        className="toast-box toast-bottom">
        <div className="text-center">
          <div className="in">
            <IonIcon icon={gameControllerOutline} className="text-primary" />
            <div className="text">{t('home.mode-not-available')}</div>
          </div>
          <div />
        </div>
      </Toast>

      <BottomMenu />
    </main>
  );
};

export default observer(Home);
