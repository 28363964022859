import { FC, Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { SentryRoutes as Routes } from '../sentry';
import { observer } from 'mobx-react-lite';

//components
import Loader from '@components/Loader';
import Layout from '@components/Layout';
import RequireAuth from '@components/RequireAuth';

//hooks
import { useSettings } from '@contexts/SettingsContext';
import { APP_ROUTER_PATHS } from '@configs/routes';
import { FeatureFlags, useFeatureFlag } from '@contexts/FeatureFlagsContext';

//pages
import Home from '@pages/Home/Home';

const PlayVsComputer = lazy(() => import('@pages/PlayVsComputer'));
const ProfileSettings = lazy(
  () => import('@pages/Profile/components/Settings/ProfileSettingsWrapper'),
);
const AuthTelegram = lazy(() => import('@pages/Auth/components/AuthTelegram'));
const Login = lazy(() => import('@pages/Auth/Login'));
const NotFound = lazy(() => import('@pages/common/PageNotFound'));
const Register = lazy(() => import('@pages/Auth/Register'));
const ForgotPassword = lazy(() => import('@pages/Auth/ForgotPassword'));
const VerifyRecoveryPassword = lazy(() => import('@pages/Auth/VerifyRecoveyPassword'));
const VerifyRegistrationEmail = lazy(() => import('@pages/Auth/VerifyRegistrationEmail'));
const NewPassword = lazy(() => import('@pages/Auth/NewPassword'));
const Notifications = lazy(() => import('@pages/Notifications/Notifications'));
const Staking = lazy(() => import('@pages/Wallet/Staking'));
const GameReview = lazy(() => import('@pages/Game/gameReview/GameReview'));
const LeaderBoard = lazy(() => import('@pages/Leaderboard/Leaderboard'));
// const Finance = lazy(() => import('@pages/Wallet/Finance'));
const Presentation = lazy(() => import('@pages/Intro/Presentation'));
const SelfProfile = lazy(() => import('@pages/Profile/SelfProfile'));
const UserProfile = lazy(() => import('@pages/Profile/UserProfile'));
// const Game = lazy(() => import('@pages/Game/Game'));
const Team = lazy(() => import('@pages/Team/Team'));
const Wallet = lazy(() => import('@pages/Wallet'));
const WalletConnect = lazy(() => import('@pages/Wallet/Connect'));
const UserPublic = lazy(() => import('@pages/Profile/UserPublic'));
const GamePublic = lazy(() => import('@pages/Game/GamePublic'));
const GameAnalysis = lazy(() => import('@pages/Game/analysis/Analysis'));
const GameWithBot = lazy(() => import('@pages/Game/bot/GameWithBot'));
const Blocked = lazy(() => import('@pages/Blocked'));
const Ads = lazy(() => import('@pages/Game/BannerRotator'));
const GameMiningMode = lazy(() => import('@pages/Game/Mode/Mining'));
const Competition = lazy(() => import('@pages/Competition/Competition'));
const CompetitionsPage = lazy(() => import('@pages/Competition/CompetitionsPage'));


export const Router: FC = observer(() => {
  const { isAllowRegistration } = useSettings();
  const isWalletEnabled = useFeatureFlag(FeatureFlags.WALLET_PAGE);
  const isWalletConnectEnabled = useFeatureFlag(FeatureFlags.WALLET_CONNECT);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* TODO: move routes path in APP_ROUTER_PATHS constant */}
        <Route path="/" element={<Layout />}>
          <Route path="/auth/telegram" element={<AuthTelegram />} />
          <Route path="/login" element={<Login />} />
          {isAllowRegistration && <Route path="/registration" element={<Register />} />}
          <Route path="/registration/verify" element={<VerifyRegistrationEmail />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/forgot/verify" element={<VerifyRecoveryPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/presentation" element={<Presentation />} />
          <Route path="*" element={<NotFound />} />

          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/profile-settings" element={<ProfileSettings />} />
            <Route path="/profile" element={<SelfProfile />} />
            <Route path="/profile/:id" element={<UserProfile />} />
            {/* <Route path="/finance" element={<Finance />} /> */}
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/competitions" element={<CompetitionsPage />} />
            <Route path="/competition/:id" element={<Competition />} />
            <Route path="/team" element={<Team />} />
            <Route path="/blocked" element={<Blocked />} />
            <Route path="/wallet" element={<Wallet />} />
            {isWalletConnectEnabled && <Route path="/wallet/connect" element={<WalletConnect />} />}
            {isWalletEnabled && <Route path="/wallet/staking" element={<Staking />} />}
            {/* <Route path={APP_ROUTER_PATHS.GAME} element={<Game />} /> */}
            <Route path="/game/mining/:timeframe" element={<GameMiningMode />} />
            <Route path="/game-bot/:color" element={<GameWithBot />} />
            <Route path={APP_ROUTER_PATHS.PLAY_VS_COMPUTER} element={<PlayVsComputer />} />
            <Route path="/game-review/:userId/:gameId" element={<GameReview />} />
            <Route path="/game-analysis" element={<GameAnalysis />} />
            <Route path="/u/:publicID" element={<UserPublic />} />
            <Route path="/g/:publicID" element={<GamePublic />} />
            <Route path="/g/:publicID/:color" element={<GamePublic />} />
            <Route path="/c/:id" element={<Competition />} />
          </Route>
          <Route path="/ads" element={<Ads />} />
        </Route>
      </Routes>
    </Suspense>
  );
});
